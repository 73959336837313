import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ContextApp from '../../../context/ContextApp';
import { authenticate, isAuthenticated } from '../../../helpers/authentication';

const LoginPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const contextApp = useContext(ContextApp);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    let isUnmounted = false;
    const authenticated = isAuthenticated(contextApp);
    // Set workspace from localstorage
    if (authenticated) {
      const from = (location.state as any || {}).from;
      const redirectTo = !from || from === '/' ? '/' : from;
      navigate(redirectTo);
    }

    // window.localStorage.setItem('activews', contextApp.)
    const auth = async () => {
      const err = await authenticate(contextApp);
      if (!isUnmounted) setIsLoading(err === null);
    }

    if (!authenticated) auth();

    return () => { isUnmounted = true; }
  }, [contextApp, location.state, navigate]);

  const schema = Yup.object().shape({
    username: Yup.string().required('Username is required.'),
    password: Yup.string().required('Password is required.'),
  });

  const initialValues = {
    username: '',
    password: '',
  }

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    const error = await authenticate(contextApp, {
      username: values.username,
      password: values.password,
    });
    if (error) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  }

  return (
    <div>
      Login Page
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({
          // errors,
          // touched,
          // handleBlur,
          // isSubmitting,
          // dirty,
          submitForm,
        }) => (
          <Form>
            <Field
              // component={TextField}
              name='username'
              type='text'
              label='Username'
              // type="email" name="email" placeholder="Email"
            />
            <Field
              // component={TextField}
              name='password'
              type='password'
              label='Password'
            />
            {
              isLoading ? (
                <div>Loading...</div>
              ) : (
                <button
                  type='submit'
                  onClick={() => {
                    submitForm();
                  }}
                  // buttonVariant='normal'
                  // style={{ width: '100%' }}
                >
                  Submit
                </button>
              )
            }
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginPage;
