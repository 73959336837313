import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import io from 'socket.io-client';
import { ServiceTypes } from '../server-interface';

const socket = io(process.env.REACT_APP_APPSERVER_URL || 'http://localhost:3030', { transports: ['websocket'] });
const app = feathers<ServiceTypes>();

app.configure(socketio(socket, { timeout: 20000}));
app.configure(auth({
  // storage: window.localStorage
  storage: window.sessionStorage as any,
}));

export enum Service {
  AUTHENTICATION= 'authentication',
  User = 'users',
  Notification = 'notification',
  FILE = 'file',
  IMAGE = 'image',
  PARTNER = 'partners',
  CLIENT = 'client',
  QUOTE = 'rfq',
  QUOTE_ITEM = 'rfqItem',
  QUOTE_SOLUTION = 'matcher',
  PART = 'part',
  SPECIALIZATIONS = 'specializations',
  VEHICLE_MAKE = 'vehicleMake',
  VEHICLE_MODEL = 'vehicleModel',
  VEHICLE = 'vehicle',
  PROCUREMENT = 'procurement',
  PROCUREMENT_ITEM = 'procurementItem',
};

export default app;
