import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import LoginPage from '../pages/Authentication/LoginPage';

// import PrivateRoute from './PrivateRoute';

const AppRoot = React.lazy(() => import('../layout/AppRoot'));

const AppRouter = () => {
  return (
    <Router>
      <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Suspense
          // fallback={<LoadingSpinner />}
        >
          <Routes>
            <Route
              path='/login'
              element={<LoginPage />}
            />
            <Route
              path='/*'
              element={<AppRoot />}
            />
          </Routes>
        </Suspense>
      </SnackbarProvider>
    </Router>
  );
};

export default AppRouter;
